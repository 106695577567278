export const userVerificationImage = (state) => {
	return state.userVerificationImage;
};

export const isSuspended = (state) => {
	return state.is_suspended;
};

export const uploadedId = (state) => {
	return state.uploaded_id_card;
};

export const defaultSignature = (state) => {
	return state.default_signature;
};
