
import "./assets/js/nav-pills.js";
import "./assets/js/ripple-effect.js";
import "../public/app-assets/css/bootstrap-extended.min.css";
// import "../public/assets/css/mediaQuery.css"
import "./assets/scss/material-dashboard.scss";
import "../public/app-assets/css/components.min.css";
import "../public/app-assets/css/bootstrap.css";
import "../public/assets/css/style.css";
export default {
  install() {},
};
