export const SET_VERIFICATION_IMAGE = (state, data) => {
	state.userVerificationImage = data;
};

export const SET_SUSPENDED_NIN = (state, susNIN) => {
	state.is_suspended = susNIN;
};

export const SET_UPLOAD_ID_CARD = (state, idCard) => {
	state.uploaded_id_card = idCard;
};

export const SET_SAVE_CONTINUE = (state, saveCont) => {
	state.saveContinue = saveCont;
};

export const SET_DEFAULT_SIGNATURE = (state, defSign) => {
	state.default_signature = defSign;
};

