import Print from '@/api/Print';
import Profile from '@/api/profile';
import { useToast } from 'vue-toast-notification';
const toast = useToast();

export const setVerificationImage = ({ commit }, formData) => {
	commit('SET_VERIFICATION_IMAGE', formData);

	// Print.store(formData)

	//   .then((response) => {
	//     commit("SET_PRINTS", response.data.data);
	//     commit("SET_PRINT_NOTIFICATION", false);
	// toast.success("Saved successfully", {
	//   timeout: 5000,
	//   position: "top-right",
	// });
	//   })
	//   .catch((error) => {
	//     if (error.response.status == 403) {
	//       commit("SET_PRINT_NOTIFICATION", true);
	//     }
	//     toast.error(`${error.response.data.data.error}`, {
	//       timeout: 5000,
	//       position: "top-right",
	//     });
	//   });
};

export const setNINSuspended = ({ commit }, formData) => {
	commit('SET_SUSPENDED_NIN', formData);
};

export const setUploadIdCard = ({ commit }, docs) => {
	Profile.uploadIdCard(docs)
		.then((response) => {
			commit('SET_UPLOAD_ID_CARD', response.data.data);
			commit('SET_SUSPENDED_NIN', false);
			toast.success('Saved successfully', {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			if (error.response.status === 401 || error.response.status == 422) {
				// commit("SET_TOKEN", null);
				console.log(error.response);
				// router.push({ name: "Login" });
			}
		});
};

export const getUserDefaultSignature = ({ commit }) => {
	Profile.getDashboard()
		.then((response) => {
			commit('SET_DEFAULT_SIGNATURE', response?.data?.data?.message);
		})
		.catch((error) => {
			console.log(error);
		});
};


