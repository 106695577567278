export default {
  documents: [],
  documentsByStatus: [],
  needToSign:[],
  documentsByStatusCompleted:[],
  declinedDocuments:{},
  document: [],
  doneEditing: null,
  resourceTools: [],
  resourceToolWithAsset: [],
  isOpenModal: false,
  message: null,
  teamMembers: []
};
